import React from 'react';

const HeaderImage = ({ className, children }) => {
    return (
        <>
            <div className="darker"></div>
            <header className={className}>
                {children}
            </header>
        </>
    );
}

export default HeaderImage;
