import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const SearchBar = () => {
    const [person, setPerson] = useState({
        firstName: null,
        lastName: null,
        deathDate: null,
        birthDate: null,
    })
    const [detailedSearch, setDetailedSearch] = useState(false)
    const handleChangePerson = (e) => {
        setPerson({
            ...person,
            [e.target.name]: e.target.value
        })
    }
    const [query, setQuery] = useState('')
    const handleQuery = (e) => {
        setQuery(e.target.value)
    }
    const seek = () => {
        if (!detailedSearch === true) {
            let tempQuery = query.trim()
            let nameData = tempQuery.split(' ')
            if (nameData.length == 1) {
                person.lastName = nameData[0] !== undefined && nameData[0] !== ''? nameData[0]: null
            } else {
                person.firstName = nameData[0] !== undefined && nameData[0] !== '' ? nameData[0]: null
                person.lastName = nameData[1] !== undefined && nameData[1] !== ''? nameData[1]: null
            } 
        }
        if (person.birthDate == null && person.deathDate == null && 
            (person.firstName == null || person.firstName.length == 0) && 
            (person.lastName == null || person.lastName.length == 0) ) {
            window.alert('Pola nie mogą być puste!')
            return
        }
        const url = new URL(window.location);
        url.pathname = '/cmentarz/szukaj/'
        for (const [key, value] of Object.entries(person)) {
            url.searchParams.set(key, value !== null ? value.trim() : value)
        }
        window.location = url
    }
    const switchButtonStyle = {
        color: 'black', 
        textTransform: 'uppercase', 
        boxShadow: 'none', 
        backgroundColor: 'transparent',
        fontWeight: '300',
        padding: 0,
        margin: 0,
        fontSize: 16,
    }
    return (
        <div className='search-bar'>
            <h2>Wyszukaj osobę  </h2>
            {detailedSearch ?
                <>
                    <div className='form-search-element'>
                        <label htmlFor='nazwisko'>Nazwisko: </label>
                        <input type="text" name="lastName" placeholder='Nazwisko' value={person.lastName} onChange={handleChangePerson} />
                    </div>
                    <div className='form-search-element'>
                        <label htmlFor='imie'>Imię: </label>
                        <input type="text" name="firstName" placeholder='Imię' value={person.firstName} onChange={handleChangePerson} />
                    </div>
                    <div className='form-search-element'>
                        <label htmlFor='rok-smierci'>Rok śmierci: </label>
                        <input type="number" name="deathDate" min="1900" max="2099" step="1" value={person.deathDate} onChange={handleChangePerson} placeholder='2022' />
                    </div>
                    <div className='form-search-element'>
                        <label htmlFor='rok-urodzenia'>Rok urodzenia: </label>
                        <input type="number" name="birthDate" min="1900" max="2099" step="1" value={person.birthDate} onChange={handleChangePerson} placeholder='1900' />
                    </div>
                    <p>Nie trzeba wypełniać wszystkich pól, wypełnij tylko te, których szukasz.</p>
                    <button onClick={() => setDetailedSearch(false)} style={switchButtonStyle}>
                        Mniej opcji wyszukiwania
                    </button>
                </>
                :
                <>
                    <div className='form-search-element'>
                        <input type="text" name="firstAndLastName" placeholder='Imię i nazwisko lub tylko nazwisko' value={query} onChange={handleQuery} />
                    </div>
                    <button onClick={() => setDetailedSearch(true)} style={switchButtonStyle}>
                        Więcej opcji wyszukiwania
                    </button>
                </>
            }
            <button onClick={seek}>
                <FontAwesomeIcon icon={faSearch} />  SZUKAJ
            </button>
        </div>
    );
}

export default SearchBar;
