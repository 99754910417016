import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCross } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
const Anniversaries = () => {
    const [data, setData] = useState([])
    const getData = async () => {
        await axios.get(
            `${process.env.GATSBY_API_URL}:8080/api/v1/noauth/person/anniversaries`,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        ).then((response) => {
            let tempData = []
            for (let person of response.data) {
                let currentYear = new Date().getFullYear() 
                let deathDate = new Date(person.deathDate)
                person['anniversaryNumber'] = currentYear - deathDate.getFullYear()
                tempData.push(person)
            }
            setData(tempData)
        })
    }

    const formatDate = (date) => {
        let tempDate = new Date(date)
        return tempDate.toLocaleDateString('pl-PL', { day: 'numeric', month: 'numeric', year: 'numeric' })
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <div className='anniversaries'>
            <h2>Rocznice śmierci</h2>
            {
                data.length > 0 ?
                data.slice(0, 5).map(person => {
                    return (
                        <div className='person'>
                            <div style={{ fontSize: 60 }}>
                                <FontAwesomeIcon icon={faCross} />
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <strong> {person.firstName} {person.lastName}</strong><br />
                                <span className='anniversary-details' style={{ fontSize: 20 }}>zm. {formatDate(person.deathDate)} r.</span><br />
                                <span className='anniversary-details' style={{ fontSize: 18, color: '#656565' }}>  {person.anniversaryNumber} ROCZNICA </span><br />
                            </div>
                        </div>
                    )
                }) :
                <span>Brak rocznic dzisiaj</span>
            }
        </div>
    );
}

export default Anniversaries;
