import React from 'react';
import { Link } from 'gatsby'
import full_map from '../../images/full_map.png'
import cemetery_map from '../../images/cmentarz_map.png'
import cemetery_map_mobile from '../../images/Cmentarz-mobile.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/free-solid-svg-icons'
const Map = () => {
    return (
        <>
            <div className='map-container'>
                <h2 style={{ marginTop: 10 }}>Mapa cmentarza</h2>
                <img src={cemetery_map} alt="Mapa cmentarza w Starych Skoszewach" width={'100%'} style={{ marginTop: -30 }} />
                <Link to='/cmentarz/kwatera/1'> <button style={{ top: '73%', left: '25%' }}>KWATERA I</button> </Link>
                <Link to='/cmentarz/kwatera/2'> <button style={{ top: '50%', left: '24%' }}>KWATERA II</button> </Link>
                <Link to='/cmentarz/kwatera/3'> <button style={{ top: '30%', left: '22%' }}>KWATERA III</button> </Link>
                <Link to='/cmentarz/kwatera/4'> <button style={{ top: '23%', left: '63%' }}>KWATERA IV</button> </Link>
                <Link to='/cmentarz/kwatera/5'> <button style={{ top: '49%', left: '52%' }}>KWATERA V</button> </Link>
                <Link to='/cmentarz/kwatera/6'> <button style={{ top: '38%', left: '74%' }}>KWATERA<br />V/DZ</button> </Link>
                <Link to='/cmentarz/kwatera/7'> <button style={{ top: '80%', left: '40%' }}>KWATERA VI</button> </Link>
                <Link to={full_map} style={{textDecoration: 'underline', fontSize: 16}}>
                    Zdjęcie cmentarza w wysokiej rozdzielczości &gt;
                </Link>
            </div>
            <div className='map-container-mobile'>
                <h2 style={{ marginTop: 10 }}>Mapa cmentarza</h2>
                <img src={cemetery_map_mobile} alt="Mapa cmentarza w Starych Skoszewach" width={'100%'} style={{ marginTop: -30 }} />
                <Link to='/cmentarz/kwatera/1'> <button style={{ width: '100%' }}><FontAwesomeIcon icon={faList} /> KWATERA I</button> </Link>
                <Link to='/cmentarz/kwatera/2'> <button style={{ width: '100%' }}><FontAwesomeIcon icon={faList} /> KWATERA II</button> </Link>
                <Link to='/cmentarz/kwatera/3'> <button style={{ width: '100%' }}><FontAwesomeIcon icon={faList} /> KWATERA III</button> </Link>
                <Link to='/cmentarz/kwatera/4'> <button style={{ width: '100%' }}><FontAwesomeIcon icon={faList} /> KWATERA IV</button> </Link>
                <Link to='/cmentarz/kwatera/5'> <button style={{ width: '100%' }}><FontAwesomeIcon icon={faList} /> KWATERA V</button> </Link>
                <Link to='/cmentarz/kwatera/6'> <button style={{ width: '100%' }}><FontAwesomeIcon icon={faList} /> KWATERA V/DZ</button> </Link>
                <Link to='/cmentarz/kwatera/7'> <button style={{ width: '100%' }}><FontAwesomeIcon icon={faList} /> KWATERA VI</button> </Link>
                <Link to={full_map} style={{textDecoration: 'underline', fontSize: 16}}>
                    Zdjęcie cmentarza w wysokiej rozdzielczości &gt;
                </Link>
            </div>

        </>
    );
}

export default Map;
