import * as React from "react"
import BannerCemetery from "../components/public/bannerCemetery"
import Layout from "../components/public/layout"
import SearchBar from "../components/public/searchBar"
import Anniversaries from "../components/public/anniversaries"
import Map from "../components/public/map"
import { Helmet } from "react-helmet"


const Cmentarz = () => {
    return (
        <Layout>
            <Helmet title={`Cmentarz parafialny | Parafia w Starych Skoszewach`} />
            <div className="container">
                <div className="left-col">
                    <BannerCemetery />
                    <Map />
                </div>
                <div className="right-col">
                    <SearchBar />
                    <Anniversaries />
                </div>
            </div>


        </Layout>
    )
}

export default Cmentarz
