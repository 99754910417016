import React from 'react';
import banner from '../../images/cmentarz.jpg'
import HeaderImage from '../private/headerImage';
const BannerCemetery = () => {
    return (
        <div className='banner'>
            <img src={banner} alt="" />
            <HeaderImage className='special'>
                Cmentarz parafialny<br/>w Starych Skoszewach
            </HeaderImage>
        </div>
    );
}

export default BannerCemetery;
